import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Layout from '../layout/Layout.vue'


const routes = [
  {
    path: '/book',
    name: 'layout',
    component: Layout,
    redirect: "/book",
    children:[
      {
        path: '/user',
        name: 'User',
        component: () => import("../views/User")
      },
      {
        path: '/book',
        name: 'Book',
        component: () => import("../views/Book")
      },
      {
        path: '/news',
        name: 'News',
        component: () => import("../views/News")
      },
      {
        path: '/person',
        name: 'Person',
        component: () => import("../views/Person")
      },
    ]
  },
  {
    path: '/',
    name: 'Login',
    component: () => import("../views/Login")
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("../views/Register")
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
