<template>
    <div>
        <el-menu style="width: 200px; min-height: calc(100vh - 50px);"
                 :default-active="path"
                 router
                 class="el-menu-vertical-demo">
<!--            <el-menu-item index="/user"><i class="el-icon-menu"></i>用户管理</el-menu-item>-->
            <el-menu-item index="/book"><i class="el-icon-menu"></i>物流单号</el-menu-item>
            <el-menu-item index="/news"><i class="el-icon-menu"></i>物流轨迹</el-menu-item>
        </el-menu>
    </div>
</template>

<script>
    import request from "../utils/request";

    export default {
        name: "Aside",
        data(){
            return{
                user:{},
                path:this.$route.path  //设置默认高亮的菜单

            }
        },
        created(){
            let userStr = sessionStorage.getItem("user") || "{}"
            this.user = JSON.parse(userStr)

            //请求服务端 确认当前登录用户的合法信息
            request.get("/user/" + this.user.id).then(res => {
                if (res.code === '0') {
                    this.user = res.data
                }
            })
        }
    }
</script>

<style scoped>

</style>
