<template>
  <div>
    <a-config-provider :locale="zhCN">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
  export default {
    name: "App",
  }
</script>

